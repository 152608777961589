.snow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;
}

.snowflake {
  position: fixed;
  top: -10px;
  color: white;
  animation: fall linear forwards;
  will-change: transform, opacity;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  pointer-events: none;
}

.snowflake::after {
  content: '❄';
}

@keyframes fall {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}

@media (prefers-reduced-motion) {
  .snowflake {
    display: none;
  }
} 