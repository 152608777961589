@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lalezar&family=Overpass+Mono:wght@300..700&family=Overpass:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

h1,h2,h3,h4,h5,h6,p,span,a,button,li,ul,ol,div {
  margin: 0;
  padding: 0;
}

.App {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  opacity: 0;
  animation: fadeIn 1s forwards;
  user-select: none;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-track {
    background-color: #333;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #353535;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

::-webkit-scrollbar-horizontal {
    height: 8px;
}

::-webkit-scrollbar-vertical {
    width: 8px;
}

body {
  background-color: #030712;
}