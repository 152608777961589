body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.scroll-buttons {
  position: fixed;
  bottom: 50%;
  right: 20px;
  transform: translateY(50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1000;
  border-radius: 50%;
}

.scroll-buttons svg {
  width: 64px;
  height: 64px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.scroll-buttons button {
  background: #0ff0f05f;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.scroll-button .up {
  transform: rotate(180deg);
}

.scroll-button .down {
  transform: rotate(0deg);
}

.cards-container {
  height: 100vh;
  width: 100%;
  background-color: #0307128f;
  /*background-color: #030712;*/
  position: relative;
  overflow: hidden;
}

.cards-container::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  opacity: 0.15;
  pointer-events: none;
  z-index: -1;
}

.header-section {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  opacity: 1;
}

.logo-area {
  width: 200px;
  height: 200px;
}

.logo {
  width: 100%;
  height: 100%;
  background-color: #0891b2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  will-change: transform;
}

.circles-area {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-right: 5rem;
}

.circle-container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #0891b2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  transition: all 1.2s cubic-bezier(0.4, 0, 0.2, 1);
  flex-shrink: 0;
  font-size: 1.2rem;
}

.circle-text {
  color: white;
  font-size: 1rem;
  opacity: 0.9;
  white-space: nowrap;
}

.circle.move-left + .circle-text,
.circle.move-center + .circle-text,
.circle.move-right + .circle-text {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.circle.move-left {
  transform: translate(-550px, 425px);
  scale: 1.5;
  opacity: 0;
}

.circle.move-center {
  transform: translate(-275px, 350px);
  scale: 1.5;
  opacity: 0;
}

.circle.move-right {
  transform: translate(50px, 275px);
  scale: 1.5;
  opacity: 0;
}

.cards-section {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  background-color: #0307128f;
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}


.info-card {
  width: 400px;
  min-height: 200px;
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  color: white;
  opacity: 0;
  transform: translateY(0px);
  transition: all 0.5s ease-in-out;
}


#left-card {
  background-color: #2563eb;
}

#center-card {
  background-color: #059669;
}

#right-card {
  background-color: #c2410c;
}

.contact-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.info-card p,
.info-card a {
  font-size: 14px;
  line-height: 1.5;
  color: white;
  text-decoration: none;
}

.contact-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: underline;
  text-decoration-color: #333;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  margin-bottom: 1rem;
}

.info-card a:hover {
  text-decoration: underline;
}

.info-card img {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
  background-color: #333;
}

@media (max-width: 1440px) {
  .cards-section {
    padding: 2rem;
  }
  
  .info-card {
    width: 350px;
  }
}

@media (max-width: 992px) {
  .cards-section {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .circle-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .circle-text {
    font-size: 0.9rem;
  }
}

.github-section {
  width: 50%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contributions-graph {
  width: 85%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 1.5rem;
  backdrop-filter: blur(10px);
}

.contribution-img {
  width: 100%;
  height: auto;
  filter: opacity(0.8) invert(1);
}

.github-stats {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
}

.contact {
  margin-top: 2rem;
}

.github-stats-header {
  font-size: 1.5rem;
  color: #f9f9f9;
  text-decoration: underline;
  text-decoration-color: #333;
  text-align: center;
}

.stat-box {
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  text-align: center;
  backdrop-filter: blur(10px);
  min-width: 120px;
  flex: 1;
  max-width: 200px;
}

.stat-box h3 {
  color: #94a3b8;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stat-box img {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
}

.stat-box span {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-top: 0.5rem;
}

.stat-box a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .github-section {
    padding: 0 1rem;
  }

  .github-stats {
    gap: 1rem;
  }

  .stat-box {
    min-width: 100px;
    padding: 0.8rem;
  }
}

.projects-section,
.projects-section-2 {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #0307128f;
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  opacity: 0;
}

.other-projects-text {
  text-transform: uppercase;
  color: white;
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
  padding: 0;
}

.timeline-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.timeline-line {
  position: absolute;
  left: 50%;
  top: 10%;
  height: 80%;
  width: 2px;
  /*background: rgba(255, 255, 255, 0.2);*/
  background: linear-gradient(to bottom, #0891b2, #059669);
  transform: translateX(-50%);
}

.timeline-item {
  position: absolute;
  width: 500px;
  transition: all 0.5s ease-out;
}

.timeline-item:nth-child(2) {
  left: 5%;
  top: 10%;
}

.timeline-item:nth-child(3) {
  right: 5%;
  top: 40%;
}

.project-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-image {
  width: 100%;
  height: 250px;
  background-color: #2563eb;
  background-size: cover;
  background-position: center;
  border-radius: 15px 15px 0 0;
}

.project-info {
  padding: 1.5rem;
  background: rgba(17, 24, 39, 0.8);
  border-radius: 0 0 15px 15px;
}

.project-info h3 {
  color: white;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.project-info p {
  color: #94a3b8;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.project-info a {
  color: #60a5fa;
  text-decoration: none;
  font-size: 0.95rem;
  display: inline-block;
  transition: color 0.2s ease;
}

.project-info a:hover {
  color: #93c5fd;
}


.visible {
  top: 0;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  pointer-events: all;
}

.moved {
  transform: translateY(0);
  opacity: 0;
  pointer-events: none;
  transition: all 2s ease-in-out;
}

@media (max-width: 1400px) {
  .timeline-item {
    width: 450px;
  }

  .project-card {
    transform: scale(0.95);
  }
}

@media (max-width: 1200px) {
  .header-section {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 3rem;
    padding: 1rem;
  }

  .github-section {
    width: 90%;
    padding: 0;
  }

  .github-stats-header {
    font-size: 2rem;
  }
  
  .cards-section {
    flex-direction: column;
    gap: 1rem;
    padding: 0;
  }

  .info-card {
    width: 80%;
  }

  .contact-title {
    font-size: 2.8rem;
  }

  .info-card p, .info-card a {
    font-size: 2.2rem;
  }

  .info-card a {
    margin-top: 16px;
    font-size: 2.5rem;
    margin: 5px 0;
  }

  .github-stats {
    justify-content: flex-start;
  }

  .circles-area {
    padding: 0;
    align-items: flex-start;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
  }

  .circle-container {
    width: 100%;
  }

  .circle {
    width: 7.5rem;
    height: 7.5rem;
    font-size: 1.8rem;
  }

  .circle-text {
    font-size: 1.8rem;
  }

  .logo-area {
    width: 150px;
    height: 150px;
  }

  .circle.move-left {
    transform: translate(0px, 425px);
    scale: 1.5;
    opacity: 0;
  }
  
  .circle.move-center {
    transform: translate(0px, 350px);
    scale: 1.5;
    opacity: 0;
  }
  
  .circle.move-right {
    transform: translate(0px, 275px);
    scale: 1.5;
    opacity: 0;
  }

  .stat-box {
    min-width: 200px;
    max-width: 300px;
  }

  .stat-box a {
    font-size: 2rem;
  }

  .stat-box h3 {
    font-size: 1.8rem;
  }

  .stat-box span {
    font-size: 1.6rem;
  }

  .stat-box img {
    width: 2rem;
    height: 2rem;
  }

  .other-projects-text {
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }

  .project-info h3 {
    font-size: 2.5rem;
    text-decoration: underline;
    text-decoration-color: #00ffd550;
    text-underline-offset: 5px;
  }

  .project-info p {
    font-size: 2rem;
  }

  .project-info a {
    font-size: 1.8rem;
  }
}

@media (max-width: 992px) {
  .timeline-item {
    width: 350px;
  }

  .timeline-container {
    flex-direction: column;
  }

  .other-projects-text {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .timeline-line {
    left: 20px;
    height: 90%;
  }

  .timeline-item {
    width: calc(100% - 60px);
    left: 40px !important;
    right: auto !important;
  }

  .timeline-item:nth-child(2) {
    top: 10%;
  }

  .timeline-item:nth-child(3) {
    top: 55%;
  }

  .cards-section {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .info-card {
    width: 90%;
    min-height: auto;
  }

  .github-stats {
    flex-direction: column;
    align-items: center;
  }

  .stat-box {
    width: 100%;
    max-width: none;
  }

  .contributions-graph {
    width: 95%;
    padding: 1rem;
  }
}

@media (max-width: 576px) {
  .logo-area {
    width: 120px;
    height: 120px;
  }

  .timeline-item {
    width: calc(100% - 40px);
    left: 30px !important;
  }

  .project-card {
    transform: scale(1);
  }

  .project-image {
    height: 180px;
  }

  .other-projects-text {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }

  .circle {
    width: 60px;
    height: 60px;
    font-size: 1.2rem;
  }

  .circle-text {
    font-size: 0.8rem;
  }
}

@media (max-height: 800px) {
  .timeline-item {
    transform: scale(0.9);
  }

  .other-projects-text {
    margin: 0;
    padding: 0;
  }

  .project-image {
    height: 200px;
  }
}

@media (max-height: 600px) {
  .timeline-item {
    transform: scale(0.8);
  }

  .project-image {
    height: 180px;
  }
}
